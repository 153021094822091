<template>
  <v-card max-height="800px">
    <v-card-title>
      <span class="headline">Escolaridad</span>
    </v-card-title>
    <v-card-text>
      <div id="printMe" style="background-color: #ffffff">
        <img width="100%" :src="require(`@/assets/images/escolaridad/header.png`)" />
        <div class="scholarShipPage back-white">
          <table class="studentData">
            <tbody>
              <tr>
                <td class="bold">Documento de Identidad</td>
                <td class="bold">{{ $props.studentData.document }}</td>
              </tr>
              <tr>
                <td class="bold">Nombre/s</td>
                <td>{{ $props.studentData.name }}</td>
              </tr>
              <tr>
                <td class="bold">Apellido/s</td>
                <td>{{ $props.studentData.lastname }}</td>
              </tr>
              <tr>
                <td class="bold">Curso</td>
                <td>{{ $props.groupData.courseName }}</td>
              </tr>
              <tr>
                <td class="bold">Carga Horaria Total</td>
                <td class="bold">{{ $props.groupData.workLoadTotal }} horas</td>
              </tr>
              <tr>
                <td class="bold">Fecha de Inicio</td>
                <td>{{ $props.groupData.start ? date($props.groupData.start).format('DD/MM/YYYY') : '-' }}</td>
              </tr>
              <tr>
                <td class="bold">Fecha de Finalización</td>
                <td class="bold">
                  {{ $props.groupData.end ? date($props.groupData.end).format('DD/MM/YYYY') : '-' }}
                </td>
              </tr>
            </tbody>
          </table>

          <table class="qualiy width-100">
            <thead>
              <tr>
                <th>MÓDULO</th>
                <th>NOTA (*)</th>
                <th>CARGA HORARIA (horas)</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="subject in $props.groupData.subjects" :key="subject.name">
                <td class="aligncenter">
                  {{ subject.name }}
                </td>
                <td class="aligncenter">
                  {{ qualification(subject) }}
                </td>
                <td class="aligncenter">
                  {{ subject.workLoad }}
                </td>
              </tr>
            </tbody>
          </table>

          <div>
            <div style="width: 100%; display: flex; align-items: center">
              <p class="bold margin-right-10">Nota Final</p>
              <p class="total">
                {{ $props.groupData.students.at(0).qualyTotal }}
              </p>
            </div>

            <div class="infoQualy margin-top-30" style="width: 100%">
              <p>
                (*) El puntaje máximo es de 100 en cada módulo. El puntaje mínimo de aprobación de cada módulo es 70.
                Calificación por Concepto: Aprobado o No Aprobado.
              </p>
              <p v-if="subjectByConcept">Módulos que se califican por concepto: {{ subjectByConcept }}</p>
            </div>

            <div class="footer" style="width: 100%">
              <p
                v-show="$props.isAdmin"
                style="margin-bottom: 20px; border-top: 1px solid #000000; padding: 5px 0px 0px 0px; width: 50%"
              >
                Por Sondert for Education
              </p>
              <p>Fecha de emisión: {{ date().format('DD/MM/YYYY') }}</p>
              <p>
                Código de verificación: <strong>{{ $props.scholarshipData._id }}</strong>
              </p>
              <span
                >Verificable hasta: {{ date($props.scholarshipData.expiration).format('DD/MM/YYYY') }} - Verificar en:
                https://gestion.sondert.com/verificar</span
              >
            </div>
          </div>
        </div>
        <div class="footerImg"><img width="100%" :src="require(`@/assets/images/escolaridad/footer.png`)" /></div>
      </div>
    </v-card-text>

    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      :filename="$props.scholarshipData._id"
      :pdf-quality="2"
      :manual-pagination="false"
      @progress="loading.download = false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="790px"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <div style="background-color: #ffffff; height: 1120px; padding: 30px">
          <img width="100%" :src="require(`@/assets/images/escolaridad/header.png`)" />
          <div class="back-white" style="width: 100%">
            <table class="studentData">
              <tbody>
                <tr>
                  <td class="bold">Documento de Identidad</td>
                  <td class="bold">{{ $props.studentData.document }}</td>
                </tr>
                <tr>
                  <td class="bold">Nombre/s</td>
                  <td>{{ $props.studentData.name }}</td>
                </tr>
                <tr>
                  <td class="bold">Apellido/s</td>
                  <td>{{ $props.studentData.lastname }}</td>
                </tr>
                <tr>
                  <td class="bold">Curso</td>
                  <td>{{ $props.groupData.courseName }}</td>
                </tr>
                <tr>
                  <td class="bold">Carga Horaria Total</td>
                  <td class="bold">{{ $props.groupData.workLoadTotal }} horas</td>
                </tr>
                <tr>
                  <td class="bold">Fecha de Inicio</td>
                  <td>{{ $props.groupData.start ? date($props.groupData.start).format('DD/MM/YYYY') : '-' }}</td>
                </tr>
                <tr>
                  <td class="bold">Fecha de Finalización</td>
                  <td class="bold">
                    {{ $props.groupData.end ? date($props.groupData.end).format('DD/MM/YYYY') : '-' }}
                  </td>
                </tr>
              </tbody>
            </table>

            <table class="qualiy width-100">
              <thead>
                <tr>
                  <th>MÓDULO</th>
                  <th>NOTA (*)</th>
                  <th>CARGA HORARIA (horas)</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="subject in $props.groupData.subjects" :key="subject.name">
                  <td class="aligncenter">
                    {{ subject.name }}
                  </td>
                  <td class="aligncenter">
                    {{ qualification(subject) }}
                  </td>
                  <td class="aligncenter">
                    {{ subject.workLoad }}
                  </td>
                </tr>
              </tbody>
            </table>

            <div>
              <div style="width: 100%; display: flex; align-items: center">
                <p class="bold margin-right-10">Nota Final</p>
                <p class="total">
                  {{ $props.groupData.students.at(0).qualyTotal }}
                </p>
              </div>

              <div class="infoQualy margin-top-30" style="width: 100%">
                <p>
                  (*) El puntaje máximo es de 100 en cada módulo. El puntaje mínimo de aprobación de cada módulo es 70.
                  Calificación por Concepto: Aprobado o No Aprobado.
                </p>
                <p v-if="subjectByConcept">Módulos que se califican por concepto: {{ subjectByConcept }}</p>
              </div>
            </div>
          </div>
        </div>

        <div style="position: absolute; bottom: 100px; padding-left: 35px">
          <p
            v-show="$props.isAdmin"
            style="margin-bottom: 20px; border-top: 1px solid #000000; padding: 5px 0px 0px 0px; width: 50%"
          >
            Por Sondert for Education
          </p>
          <p>Fecha de emisión: {{ date().format('DD/MM/YYYY') }}</p>
          <p>
            Código de verificación: <strong>{{ $props.scholarshipData._id }}</strong>
          </p>
          <span
            >Verificable hasta: {{ date($props.scholarshipData.expiration).format('DD/MM/YYYY') }} - Verificar en:
            https://gestion.sondert.com/verificar</span
          >
        </div>

        <div style="position: absolute; bottom: 0px; padding: 30px 30px 25px 30px">
          <img width="100%" :src="require(`@/assets/images/escolaridad/footer.png`)" />
        </div>
      </section>
    </vue-html2pdf>

    <v-card-actions class="margin-top-10">
      <v-switch
        v-model="$props.scholarshipData.publicInfo"
        class="mt-0"
        classcolor="success"
        label="Mostrar detalle en la verificación"
        @change="setPublicInfo"
        hide-details
      ></v-switch>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon class="ml-1" color="primary" dark v-bind="attrs" v-on="on">{{ icons.mdiHelpCircle }}</v-icon>
        </template>
        <span>Activandolo se mostraran las calificaciónes en la verificación</span>
      </v-tooltip>
      <v-spacer></v-spacer>
      <v-btn color="error" @click="$emit('close')">Cerrar</v-btn>
      <v-btn color="primary" type="submit" @click="generateReport" :loading="loading.download"
        ><v-icon>{{ icons.mdiPdfBox }}</v-icon> <span>Descargar</span></v-btn
      >
      <v-btn color="primary" type="submit" v-if="!$vuetify.breakpoint.smAndDown" v-print="printObj"
        ><v-icon>{{ icons.mdiPrinter }}</v-icon> <span>Imprimir</span></v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import snackbarPlugin from '@/plugins/snackbar'
import store from '@/store'
import { mdiPrinter, mdiHelpCircle, mdiPdfBox } from '@mdi/js'
import { inject, ref } from 'vue'
import print from 'vue-print-nb'
import VueHtml2pdf from 'vue-html2pdf'

export default {
  components: { VueHtml2pdf },
  props: {
    groupData: {
      type: Object,
      required: true,
    },
    studentData: {
      type: Object,
      required: true,
    },
    scholarshipData: {
      Object,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  directives: {
    print,
  },
  setup(props) {
    const html2Pdf = ref(null)
    const loading = ref({ download: false })

    const printInvoice = () => {
      window.print()
    }

    const qualification = subject => {
      let qualy = props.groupData.students.at(0).qualifications.find(qualy => qualy.subject == subject._id)
      return qualy && qualy.qualification > -1
        ? subject.qualificationType == 'course'
          ? qualy.qualification
          : qualy.qualification == 1
          ? 'Aprobado'
          : 'No Aprobado'
        : ''
    }

    const setPublicInfo = enabled => {
      store
        .dispatch('app-group/updateScholarship', props.scholarshipData)
        .then(r => {
          snackbarPlugin.showMessage({ content: 'Actualizado Correctamente', color: 'success', timeout: 10000 })
        })
        .catch(e => {
          snackbarPlugin.showMessage({ content: e.response.data.message, color: 'error', timeout: 10000 })
        })
    }

    const date = inject('date')

    const printObj = {
      id: 'printMe',
      popTitle: 'Escolaridad',
      extraCss: '',
      extraHead: '<meta http-equiv="Content-Language" content="es" />',
      beforeOpenCallback(vue) {
        vue.printLoading = true
      },
      openCallback(vue) {
        vue.printLoading = false
      },
      closeCallback(vue) {},
    }

    const generateReport = () => {
      loading.value.download = true
      html2Pdf.value.generatePdf()
    }

    const subjectByConcept = props.groupData.subjects
      ? props.groupData.subjects
          .filter(s => {
            if (s.qualificationType !== 'course') return s.name
          })
          .map(s => s.name)
          .join(', ')
      : ''

    return {
      loading,
      html2Pdf,
      printObj,
      qualification,
      setPublicInfo,
      date,
      subjectByConcept,

      // invoiceDescription,
      printInvoice,
      generateReport,

      // icons
      icons: {
        mdiHelpCircle,
        mdiPrinter,
        mdiPdfBox,
      },
    }
  },
}
</script>

<style>
@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }

  div.footer {
    position: fixed;
    left: 10px;
    right: 0;
    bottom: 100px;
  }

  div.footerImg {
    position: fixed;
    width: 100%; /* put the image width here */
    bottom: 0;
    left: 0;
  }

  * {
    -webkit-print-color-adjust: exact !important; /*Chrome, Safari */
    color-adjust: exact !important; /*Firefox*/
  }
}

.back-white {
  color: #000000;
  padding-top: 80px;
  padding-left: 10px;
  padding-right: 5px;
}

.bold {
  font-weight: bold;
}

table.qualiy {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

table.qualiy,
table.qualiy th,
table.qualiy td {
  border: 2px solid #b7b7b7;
  border-collapse: collapse;
}

table.studentData td {
  width: 200px;
  height: 30px;
}

table.qualiy th {
  padding: 0px 30px 0px 30px;
}

.aligncenter {
  text-align: center;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-100 {
  margin-top: 100px;
}

.margin-top-50 {
  margin-top: 50px;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-right-10 {
  margin-right: 10px;
}

.margin-left-30 {
  margin-left: 30px;
}

.margin-bottom-10 {
  margin-left: 10px;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

div.header p {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0px;
}

div.infoQualy p {
  margin-bottom: 15px;
}

.total {
  border-width: 2px;
  border-style: solid;
  width: 100px;
  padding: 5px;
  text-align: center;
  font-weight: bold;
}
</style>
