<template>
  <v-card max-height="800px">
    <v-card-title>
      <span class="headline">Certificado</span>
    </v-card-title>
    <v-card-text>
      <pdf :src="$store.state.apidomain + '/certificate/' + certificateData._id + '.pdf'" :page="1"> </pdf>
    </v-card-text>

    <v-card-actions class="margin-top-10">
      <v-switch
        v-model="certificateData.public"
        class="mt-0"
        classcolor="success"
        label="Mostrar en la verificación"
        @change="setPublicInfo"
        hide-details
      ></v-switch>
      <v-spacer></v-spacer>
      <v-btn color="error" @click="$emit('close')">Cerrar</v-btn>
      <v-btn color="primary" type="submit" @click="download"
        ><v-icon>{{ icons.mdiDownload }}</v-icon> <span>Descargar</span></v-btn
      >
      <v-btn color="primary" :href="certificateData.linkedin" target="_blank"
        ><v-icon>{{ icons.mdiLinkedin }}</v-icon> <span>Agregar al perfil</span></v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import pdf from 'vue-pdf'
import store from '@/store'
import snackbarPlugin from '@/plugins/snackbar'
import { mdiDownload, mdiHelpCircle, mdiLinkedin } from '@mdi/js'

export default {
  components: {
    pdf,
  },
  props: {
    certificateData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const setPublicInfo = enabled => {
      store
        .dispatch('app-group/updateCertificate', props.certificateData)
        .then(r => {
          snackbarPlugin.showMessage({ content: 'Actualizado Correctamente', color: 'success', timeout: 10000 })
        })
        .catch(e => {
          snackbarPlugin.showMessage({ content: e.response.data.message, color: 'error', timeout: 10000 })
        })
    }

    const download = () => {
      store
        .dispatch('app-group/downloadCertificate', props.certificateData._id + '.pdf')
        .then(res => {
          const file = window.URL.createObjectURL(new Blob([res.data]))

          const link = document.createElement('a')
          link.href = file
          link.setAttribute('download', props.certificateData._id + '.pdf')
          document.body.appendChild(link)
          link.click()
        })
        .catch(e => {
          snackbarPlugin.showMessage({ content: e.response.data.message, color: 'error', timeout: 10000 })
        })
    }

    return {
      download,
      setPublicInfo,

      // icons
      icons: {
        mdiHelpCircle,
        mdiDownload,
        mdiLinkedin,
      },
    }
  },
}
</script>
